import HeroLayout2 from "components/common/hero/HeroLayout2";
import InfoSlider from "components/common/InfoSlider";
import GlobalLayout from "components/page/GlobalLayout";
import CustomerStoryCarousel from "components/vs/updated/CustomerStoryCarousel";
import WebinarAboutSection from "components/webinar/WebinarAboutSection";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

export default function CreatingSaferSchoolsWebinar() {
  const renderContent = data => {
    const headerSection = {
      preTitle: "October 11 @ 11:30AM EST",
      title: "Assessing Your School’s Physical Security",
      paragraph:
        "Create a safe campus environment and protect students and faculty with confidence. Connection and Rhombus are your long-term school safety partners, so you can stay ahead of threats and streamline security at scale.",
      formId: "b6c4c18d-cf71-4b8b-beea-91139a374230",
      image: data.headerImage,
      mobileImage: data.headerImage,
    };

    const learnLines = [
      "Step-by-step walkthrough of how to assess your campus’ physical security",
      "How to proactively identify vulnerabilities and keep up with evolving security needs",
      "Why cloud-native solutions help campuses and school districts manage safety effectively",
      "Benefits of unifying your cameras, sensors, and access control on one platform",
    ];

    const logos = [data.logo1, data.logo2];

    const speakers = [
      {
        name: "Doug Sutter",
        title: "Director of Sales - SLED",
        company: "Rhombus",
        image: data.doug,
      },
      {
        name: "Jo Ann Dempsey",
        title: "Business Development Manager",
        company: "Connection",
        image: data.Jo,
      },
    ];

    const infoSlider = [
      {
        tabTitle: "User-Friendly",
        title: "Keep Students and Faculty Safe with an Easy-to-Use Platform",
        description:
          "Fortify school security with cloud-based cameras and real-time AI alerts for proactive threat detection anytime, anywhere. Cover blind spots, support School Resource Officers, and streamline investigations with easy-to-use tools.",
        image: data.infoSlide1,
      },
      {
        tabTitle: "Proactive Resolution",
        title:
          "Speed Up Emergency Response and Coordinate with First Responders",
        description:
          "Act swiftly in emergencies and access a range of solutions, including person-of-interest detection, panic buttons, and alarm monitoring. Verify threats, reduce false alarms, and relay key details to emergency services efficiently.",
        image: data.infoSlide2,
      },
      {
        tabTitle: "Prevent Vaping",
        title: "Monitor Air Quality and Detect Student Vaping",
        description:
          "Maintain a healthy school environment and reduce student vaping with air quality and vape/smoke/THC smart sensors. Automatically detect e-cigarette and tobacco use in real time and obtain actionable evidence while preserving student privacy. ",
        image: data.infoSlide3,
      },
      {
        tabTitle: "Monitor Access Points",
        title: "Control Campus Perimeters and Building Access",
        description:
          "Centrally manage unlimited doors, buildings, and credentials from anywhere. Detect unauthorized visitors and quickly understand who’s on school property by pairing access events with synchronized video, badge data, and timestamps. ",
        image: data.infoSlide4,
      },
    ];

    const customerStories = {
      title: "Industry Leaders Trust Rhombus as their Security Solution",
      cards: [
        {
          img: data.story1,
          logo: {
            img: data.cslogo1,
            width: "100px",
            alt: "CAU",
          },
          quote:
            "“I have been in the technology industry for many years, and I like the way Rhombus does business. They genuinely care about creating a product and service that fits their users’ needs.”",
          link: "/case-studies/clark-atlanta-university/",
        },
        {
          img: data.story2,
          logo: {
            img: data.cslogo2,
            width: "95px",
            alt: "Birch Tree Academy",
          },
          quote:
            "“Using Rhombus is so much better than anything else out there. The system is dependable, the software is great, and it’s just easy to use and manage. I can’t recommend it enough.”",
          link: "/case-studies/birch-tree-academy/",
        },
        {
          img: data.story3,
          logo: {
            img: data.cslogo3,
            width: "75px",
            alt: "Nemaha School District",
          },
          quote:
            "“Having remote access aligns with how organizations operate in today’s world. The Rhombus mobile app makes it easy for me and my staff to log-in and check if everything is okay across multiple buildings.”",
          link: "/case-studies/nemaha-school-district/",
        },
        {
          img: data.story4,
          logo: {
            img: data.cslogo4,
            width: "150px",
            alt: "Jesuit High School",
          },
          quote:
            "“On any campus, there are only so many security guards, but countless blind spots. Rhombus Systems allows us to be everywhere to better protect our students and staff.”",
          link: "/case-studies/jesuit-high-school/",
        },
      ],
    };

    return (
      <GlobalLayout>
        <Helmet>
          <title>Rhombus - Creating Safer Schools Live Webinar</title>
          <meta
            name="description"
            content="Learn how to create a safe campus environment and protect students and faculty with confidence in this live webinar"
          />
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <HeroLayout2
          data={headerSection}
          formTitle="Register for the Webinar"
        />
        <WebinarAboutSection
          about={[
            "In this webinar, we walk through how to assess campus safety, and modern solutions that make it easier for schools create a safe environment for students and faculty in both the short and long term.",
          ]}
          alt="teacher helping student on computer"
          learnLines={learnLines}
          speakers={speakers}
          image={data.about}
          learnTitle="What You’ll Learn in 45 Minutes"
          color="var(--nuetral-100)"
          logos={logos}
        />
        <InfoSlider data={infoSlider} title="The Benefits of Rhombus" />
        <CustomerStoryCarousel
          data={customerStories.cards}
          title={customerStories.title}
        />
      </GlobalLayout>
    );
  };

  const query = graphql`
    query {
      headerImage: file(
        relativePath: { eq: "components/teams/img/safer-schools-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      doug: file(
        relativePath: { eq: "components/webinar/img/doug-blue-bg-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      Jo: file(relativePath: { eq: "components/webinar/img/jo-ann.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      about: file(
        relativePath: {
          eq: "components/live-webinar/img/about-safer-schools-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      infoSlide1: file(
        relativePath: {
          eq: "components/industries/updated/img/education/cameras-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide2: file(
        relativePath: {
          eq: "components/industries/updated/img/education/edu-alarm-monitoring.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide3: file(
        relativePath: {
          eq: "components/industries/updated/img/education/environmental-monitoring-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide4: file(
        relativePath: {
          eq: "components/industries/updated/img/education/privacy-screen-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      cslogo1: file(
        relativePath: { eq: "components/common/logos/cau-logo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      cslogo2: file(
        relativePath: { eq: "components/common/logos/birch-tree-logo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      cslogo3: file(
        relativePath: { eq: "components/common/logos/nemaha-logo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      cslogo4: file(
        relativePath: { eq: "components/common/logos/jesuit-logo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      story1: file(
        relativePath: { eq: "components/webinar/updated/img/cau-story-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      story2: file(
        relativePath: {
          eq: "components/webinar/updated/img/birch-tree-story-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      story3: file(
        relativePath: {
          eq: "components/webinar/updated/img/nemaha-story-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      story4: file(
        relativePath: {
          eq: "components/webinar/updated/img/jesuit-story-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      logo1: file(
        relativePath: { eq: "components/live-webinar/img/rhombus-logo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      logo2: file(
        relativePath: { eq: "components/live-webinar/img/connection-logo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
    }
  `;
  return <StaticQuery query={query} render={renderContent} />;
}
